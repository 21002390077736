import  * as actionTypes from 'store/actions/types'
import { updateObject } from 'utils';

let profile = {}
let file = {}
if(typeof window !== 'undefined') {
    profile = {payload: JSON.parse(localStorage.getItem('profile'))} || {}
    file = JSON.parse(localStorage.getItem('file')) || {}
}


const initialState = {
    profile,
    confirmationModal: false,
    welcomeModal: false,
    parsing: {},
    file,
    tagging: {},
    asking: {},
    profileNoCv: {},
    callBackUrl: "",
}


const showConfirmationModal = (state, action) => {
    return updateObject( state, {
        confirmationModal: action.value
    });
}

const showWelcomeModal = (state, action) => {
    return updateObject( state, {
        welcomeModal: action.value
    });
}


const addProfileRequest = (state, action) => {
    return updateObject( state, {
        profile: { post: true, r: true, payload: state.profile }
    });
}

const addProfileSuccess = (state, action) => {
    return updateObject( state, {
        parsing: { payload: action.parsing },
        profile: { post: true, s: true, payload: action.profile },
        file: JSON.parse(action.file),
        callBackUrl: action.callBackUrl
    });
}

const addProfileFail = (state, action) => {
    return updateObject( state, {
        profile: { post: true, f: true }
    });
}

const postTaggingRequest = (state, action) => {
    return updateObject( state, {
        tagging: {  r: true, payload: state.tagging }
    });
}

const postTaggingSuccess = (state, action) => {
    //TODO: check if all the tagging is resolved before setting it to true this must be changed to a dynamic nuber instead of 6
    const RESOLVED_NUMBER = Object.keys(action.tagging).length
    return updateObject( state, {
        tagging: { s: RESOLVED_NUMBER === 6 ? true : false,  r:  RESOLVED_NUMBER < 6 ? true : false, payload: action.tagging },
    });
}

const postTaggingFail = (state, action) => {
    return updateObject( state, {
        tagging: { f: true }
    });
}


const getAskingRequest = (state, action) => {
    return updateObject( state, {
        asking: {  r: true, payload: state.asking }
    });
}

const getAskingSuccess = (state, action) => {
    return updateObject( state, {
        asking: {  s: true, payload: action.asking },
    });
}

const getAskingFail = (state, action) => {
    return updateObject( state, {
        asking: { f: true }
    });
}


const addProfileStoringRequest = (state, action) => {
    return updateObject( state, {
        profile: { post: true, r: true, payload: state.profile }
    });
}

const addProfileStoringSuccess = (state, action) => {
    return updateObject( state, {
        profile: { post: true, s: true, payload: action.profile },
    });
}

const addProfileStoringFail = (state, action) => {
    return updateObject( state, {
        profile: { post: true, f: true }
    });
}

const getProfileStoringRequest = (state, action) => {
    return updateObject( state, {
        profile: { r: true, payload: state.profile }
    });
}

const getProfileStoringSuccess = (state, action) => {
    return updateObject( state, {
        profile: { s: true, payload: action.profile },
        file: JSON.parse(action.file)
    });
}

const getProfileStoringFail = (state, action) => {
    return updateObject( state, {
        profile: { f: true }
    });
}



const getProfileRequest = (state, action) => {
    return updateObject( state, {
        profile: { r: true, payload: state.profile }
    });
}

const getProfileSuccess = (state, action) => {
    return updateObject( state, {
        profile: { s: true, payload: action.profile },
        file: JSON.parse(action.file)
    });
}

const getProfileFail = (state, action) => {
    return updateObject( state, {
        profile: { f: true, payload: state.profile }
    });
}


const addProfileStoringNoCvRequest = (state, action) => {
    return updateObject( state, {
        profileNoCv: {r: true }
    });
}

const addProfileStoringNoCvSuccess = (state, action) => {
    return updateObject( state, {
        profileNoCv: {s: true }
    });
}

const addProfileStoringNoCvFail = (state, action) => {
    return updateObject( state, {
        profileNoCv: {f: true }
    });
}

const putProfileRequest = (state, action) => {
    return updateObject( state, {
        profile: { put: true, r: true, payload: state.profile?.payload || state.profile }
    });
}

const putProfileSuccess = (state, action) => {
    return updateObject( state, {
        profile: { put: true, s: true, payload: action.profile }
    });
}

const putProfileFail = (state, action) => {
    return updateObject( state, {
        profile: { put: true, f: true }
    });
}

const removeProfile = (state, action) => {
    return updateObject( state, {
        profile: {},
        file: {}
    })
}

const profileReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ADD_PROFILE_REQUEST: return addProfileRequest( state, action );
        case actionTypes.ADD_PROFILE_SUCCESS: return addProfileSuccess( state, action );
        case actionTypes.ADD_PROFILE_FAIL: return addProfileFail( state, action );

        case actionTypes.POST_TAGGING_REQUEST: return postTaggingRequest( state, action );
        case actionTypes.POST_TAGGING_SUCCESS: return postTaggingSuccess( state, action );
        case actionTypes.POST_TAGGING_FAIL: return postTaggingFail( state, action );
        
        case actionTypes.GET_ASKING_REQUEST: return getAskingRequest( state, action );
        case actionTypes.GET_ASKING_SUCCESS: return getAskingSuccess( state, action );
        case actionTypes.GET_ASKING_FAIL: return getAskingFail( state, action );

        case actionTypes.PUT_PROFILE_REQUEST: return putProfileRequest( state, action );
        case actionTypes.PUT_PROFILE_SUCCESS: return putProfileSuccess( state, action );
        case actionTypes.PUT_PROFILE_FAIL: return putProfileFail( state, action );

        case actionTypes.GET_PROFILE_REQUEST: return getProfileRequest( state, action );
        case actionTypes.GET_PROFILE_SUCCESS: return getProfileSuccess( state, action );
        case actionTypes.GET_PROFILE_FAIL: return getProfileFail( state, action );

        case actionTypes.ADD_PROFILE_STORING_NOCV_REQUEST: return addProfileStoringNoCvRequest( state, action );
        case actionTypes.ADD_PROFILE_STORING_NOCV_SUCCESS: return addProfileStoringNoCvSuccess( state, action );
        case actionTypes.ADD_PROFILE_STORING_NOCV_FAIL: return addProfileStoringNoCvFail( state, action );


        case actionTypes.GET_PROFILE_STORING_REQUEST: return getProfileStoringRequest( state, action );
        case actionTypes.GET_PROFILE_STORING_SUCCESS: return getProfileStoringSuccess( state, action );
        case actionTypes.GET_PROFILE_STORING_FAIL: return getProfileStoringFail( state, action );

        case actionTypes.ADD_PROFILE_STORING_REQUEST: return addProfileStoringRequest( state, action );
        case actionTypes.ADD_PROFILE_STORING_SUCCESS: return addProfileStoringSuccess( state, action );
        case actionTypes.ADD_PROFILE_STORING_FAIL: return addProfileStoringFail( state, action );
        
        case actionTypes.GET_PROFILE_STORING_REQUEST: return getProfileStoringRequest( state, action );
        case actionTypes.GET_PROFILE_STORING_SUCCESS: return getProfileStoringSuccess( state, action );
        case actionTypes.GET_PROFILE_STORING_FAIL: return getProfileStoringFail( state, action );

        case actionTypes.REMOVE_PROFILE: return removeProfile( state, action );
        case actionTypes.SHOW_CONFIRMATION_MODAL: return showConfirmationModal( state, action );
        case actionTypes.SHOW_WELCOME_MODAL: return showWelcomeModal(state, action);

        default: return state;
    }
};

export default profileReducer;