import { sharedPreferences } from "../styles/theme"
import moment from 'moment';

// breakpoints.lg needs to be cleaned and parsed since it is a string like '1200px'
export const detectMobileAndTablet = windowWidth =>
  windowWidth <
  parseInt(sharedPreferences.breakpoints.lg.match(/\d+/gi).join(""))

export const detectMobile = windowWidth =>
  windowWidth <
  parseInt(sharedPreferences.breakpoints.sm.match(/\d+/gi).join(""))

// detect server-side-rendering to fix bugs while gatsby build
export const isSSR = typeof window === "undefined"

export const removeOccurrences  = arr =>  {
  return Array.from(new Set(arr.map(a => a.text)))
  .map(text => {
    return arr.find(a => a.text === text)
  })
}

export const removeOccurrencesString = arr => {
  return Array.from(new Set(arr.map(a => a.value)))
  .map(value => {
    return arr.find(a => a.value === value)
  })
};

// used to parse the publication date of medium articles
export const parseDate = date => {
  const year = date.substring(0, 4)
  const month = date.substring(5, 7)
  const day = date.substring(8, 10)

  switch (month) {
    case "01":
      return day + " January " + year
    case "02":
      return day + " February " + year
    case "03":
      return day + " March " + year
    case "04":
      return day + " April " + year
    case "05":
      return day + " May " + year
    case "06":
      return day + " June " + year
    case "07":
      return day + " July " + year
    case "08":
      return day + " August " + year
    case "09":
      return day + " September " + year
    case "10":
      return day + " October " + year
    case "11":
      return day + " November " + year
    case "12":
      return day + " December " + year
    default:
      return "No publication date"
  }
}

export const updateObject = (oldObject, updatedProperties) => {
  return {
      ...oldObject,
      ...updatedProperties
  };
};

export const  buildQueryString =  (url, queryObject) => {
  let queryString = `${url}?`;
  Object.keys(queryObject).forEach(item => {
      if (typeof queryObject[item] === 'string' || queryObject[item] instanceof String) {
          queryString += `${item}=${queryObject[item]}&`;
      } else {
          queryString += `${item}=${JSON.stringify(queryObject[item])}&`;
      }
  });
  return queryString; 
}

export const  rangeDateToIsoDate =  (rangeDate) => {
  let isoDate = null
  if (rangeDate === '1w') {
      isoDate = moment().endOf('day').subtract(1, 'week');
  } else if (rangeDate === '2w') {
      isoDate = moment().endOf('day').subtract(2, 'week');
  } else if (rangeDate === '3w') {
      isoDate = moment().endOf('day').subtract(3, 'week');
  } else if (rangeDate === '1m') {
      isoDate = moment().endOf('day').subtract(1, 'month');
  } else if (rangeDate === '2m') {
      isoDate = moment().endOf('day').subtract(2, 'month');
  } else if (rangeDate === '3m') {
      isoDate = moment().endOf('day').subtract(3, 'month');
  } else if (rangeDate === '4m') {
      isoDate = moment().endOf('day').subtract(4, 'month');
  } else if (rangeDate === '5m') {
      isoDate = moment().endOf('day').subtract(5, 'month');
  } else if (rangeDate === '6m') {
      isoDate = moment().endOf('day').subtract(6, 'month');
  } else if (rangeDate === '1y') {
      isoDate = "";
  } else if (rangeDate === '2y') {
      isoDate = moment().endOf('day').subtract(2, 'year');
  } else if (rangeDate === '3y') {
      isoDate = moment().endOf('day').subtract(3, 'year');
  } else {
      isoDate = moment().endOf('day');
  }

  return isoDate
}