import  * as actionTypes from 'store/actions/types'
import { updateObject } from 'utils';
import { api } from "config"

let queryObject = {}
let boardFilters = {}
let jobs = {}
let currentTab = "searching"
if (typeof window !== 'undefined') {
  queryObject = localStorage.getItem('queryObject') || {
    board_keys: api.BOARD_KEYS,
    agent_key: api.AGENT_KEY,
    tags_included: [[], []],
    names: [],
    limit: 70,
    page: 1,
    sort_by: 'created_at',
    order_by: 'asc',
    location_distance: 70,
    location_geopoint: {},
    use_agent: 0,
    profile_key: '',
    source_key: api.SOURCE_KEY,
    text_keywords: [],
    totalPage : 0,
    status: false,
  }

  boardFilters = JSON.parse(localStorage.getItem('boardFilters')) || {
    created_at: {
      skills: {enabled: [], disabled: []},
      languages: {enabled: [], disabled: []},
      jobs: [],
      locations: [],
      categories: [],
      types: [],
      orderBy: 'desc',
      sortBy: 'created_at',
      useAgent: false,
      location_distance: 70,
      date_range_min: '1y'
    },
    searching: {
      skills: {enabled: [], disabled: []},
      languages: {enabled: [], disabled: []},
      jobs: [],
      locations: [],
      categories: [],
      types: [],
      orderBy: 'desc',
      sortBy: 'searching',
      useAgent: false,
      location_distance: 70,
      date_range_min: '1y'
    }
  }

  jobs = JSON.parse(localStorage.getItem('jobs')) || []
  currentTab = localStorage.getItem('currentTab') || 'searching'
}



const initialState = {
  job: {},
  jobs: jobs,
  similarJobs: {},
  recommandedJobs: {},
  jobsCategory: {},
  jobsCategory1: [],
  jobsCategory2: [],
  jobsCategory3: [],
  jobsCategory4: [],
  queryObject,
  boardFilters: boardFilters,
  documentParsing: {},
  documentRevealing: {},
  currentTab,
}


const updateBoardFilters = (state, action) => {
  let storageBoardFilters = JSON.parse(localStorage.getItem('boardFilters')) || state.boardFilters
  if(action.boardFilters.hasOwnProperty('scoring') || action.boardFilters.hasOwnProperty('searching') || action.boardFilters.hasOwnProperty('created_at')) {
    storageBoardFilters = action.boardFilters
  } else {
    storageBoardFilters[state.currentTab] = action.boardFilters
  }
  localStorage.setItem('boardFilters', JSON.stringify(storageBoardFilters))
  return updateObject(state, {
    boardFilters: storageBoardFilters,
  })
}


const updateCurrentTab = (state, action) => {
  localStorage.setItem('currentTab', action.tab)
  return updateObject(state, { 
    currentTab: action.tab,
  })
}

const initJobsCategoriesState = (state, action) => {
  if(action.payload === null) {
    return updateObject(state, {
      jobsCategory1: [],
      jobsCategory2: [],
      jobsCategory3: [],
      jobsCategory4: [],
    })
  } else {
    return updateObject(state, {
      jobsCategory1: action.payload === 1 ?  (state.jobsCategory2?.payload ? state.jobsCategory2 : []) : state.jobsCategory1,
      jobsCategory2: action.payload === 2 || action.payload === 1 ?  (state.jobsCategory2?.payload ? state.jobsCategory3 : []) : state.jobsCategory2,
      jobsCategory3: action.payload === 3 || action.payload === 2 || action.payload === 1 ?  [] : state.jobsCategory3,
      jobsCategory4: action.payload === 4 || action.payload === 3 || action.payload === 2 ?  [] : state.jobsCategory4,
    })
  }
}

const fetchJobsRequest = (state, action) => {
  return updateObject( state, {
    jobs: { r: true, payload: state.jobs },
    jobsCategory: {}
  });
}

const fetchJobsSuccess = (state, action) => {
  return updateObject( state, {
    jobs: { s: true, payload: action.jobs }
  });
}

const fetchJobsFail = (state, action) => {
  return updateObject( state, {
    jobs: { f: true }
  });
}


const fetchSimilarJobsRequest = (state, action) => {
  return updateObject( state, {
    similarJobs: { r: true, payload: state.jobs }
  });
}

const fetchSimilarJobsSuccess = (state, action) => {
  return updateObject( state, {
    similarJobs: { s: true, payload: action.jobs }
  });
}

const fetchSimilarJobsFail = (state, action) => {
  return updateObject( state, {
    similarJobs: { f: true }
  });
}

const fetchRecommandedJobsRequest = (state, action) => {
  return updateObject( state, {
    recommandedJobs: { r: true, payload: state.jobs }
  });
}

const fetchRecommandedJobsSuccess = (state, action) => {
  return updateObject( state, {
    recommandedJobs: { s: true, payload: action.jobs }
  });
}

const fetchRecommandedJobsFail = (state, action) => {
  return updateObject( state, {
    recommandedJobs: { f: true }
  });
}

const fetchJobsCategoryRequest = (state, action) => {
  let jobs = state.jobsCategory;
  return updateObject( state, {
    jobsCategory: {
      ...jobs,
      [action.category]: { r: true, payload: state.jobsCategory[action.category]?.payload }
    }
  });
}

const fetchJobsCategorySuccess = (state, action) => {
  let jobs = state.jobsCategory;
  if(state.jobsCategory[`${action.category}`].payload) {
    return updateObject( state, {
      jobsCategory: {
        ...jobs, 
        [action.category]: {
          s: true,
          payload: {
            jobs: state.jobsCategory[`${action.category}`].payload.jobs.concat(action.jobs.jobs),
            meta: action.jobs.meta 
          }
        }
      }
    });  
  } else {
    return updateObject( state, {
      jobsCategory: {
        ...jobs,
        [action.category]: {
          s: true,
          payload:  action.jobs
        }
      }
    });
  }
}

const fetchJobsCategoryFail = (state, action) => {
  let jobs = state.jobsCategory;
  return updateObject( state, {
    jobsCategory: {
      ...jobs,
      [action.category]: { f: true }
    }
  });
}


const fetchJobsCategory1Request = (state, action) => {
  return updateObject( state, {
    jobsCategory1: { r: true, payload: state.jobsCategory1.payload }
  });
}

const fetchJobsCategory1Success = (state, action) => {
  if(state.jobsCategory1.payload) {
    return updateObject( state, {
      jobsCategory1: {
        s: true, 
        payload: {
          jobs: state.jobsCategory1.payload.jobs.concat(action.jobsCategory1.jobs),
          meta: action.jobsCategory1.meta 
        }
      }
    });  
  } else {
    return updateObject( state, {
      jobsCategory1: { s: true, payload: action.jobsCategory1 }
    });
  }
}

const fetchJobsCategory1Fail = (state, action) => {
  return updateObject( state, {
    jobsCategory1: { f: true }
  });
}

const fetchJobsCategory2Request = (state, action) => {
  return updateObject( state, {
    jobsCategory2: { r: true, payload: state.jobsCategory2.payload }
  });
}

const fetchJobsCategory2Success = (state, action) => {
  if(state.jobsCategory2.payload) {
    return updateObject( state, {
      jobsCategory2: {
        s: true, 
        payload: {
          jobs: state.jobsCategory2.payload.jobs.concat(action.jobsCategory2.jobs),
          meta: action.jobsCategory2.meta 
        }
      }
    });  
  } else {
    return updateObject( state, {
      jobsCategory2: { s: true, payload: action.jobsCategory2 }
    });
  }
}

const fetchJobsCategory2Fail = (state, action) => {
  return updateObject( state, {
    jobsCategory2: { f: true }
  });
}

const fetchJobsCategory3Request = (state, action) => {
  return updateObject( state, {
    jobsCategory3: { r: true, payload: state.jobsCategory3.payload }
  });
}

const fetchJobsCategory3Success = (state, action) => {
  if(state.jobsCategory3.payload) {
    return updateObject( state, {
      jobsCategory3: {
        s: true, 
        payload: {
          jobs: state.jobsCategory3.payload.jobs.concat(action.jobsCategory3.jobs),
          meta: action.jobsCategory3.meta 
        }
      }
    });  
  } else {
    return updateObject( state, {
      jobsCategory3: { s: true, payload: action.jobsCategory3 }
    });
  }
}

const fetchJobsCategory3Fail = (state, action) => {
  return updateObject( state, {
    jobsCategory3: { f: true }
  });
}

const fetchJobsCategory4Request = (state, action) => {
  return updateObject( state, {
    jobsCategory4: { r: true, payload: state.jobsCategory4.payload }
  });
}

const fetchJobsCategory4Success = (state, action) => {
  if(state.jobsCategory4.payload) {
    return updateObject( state, {
      jobsCategory4: {
        s: true, 
        payload: {
          jobs: state.jobsCategory4.payload.jobs.concat(action.jobsCategory4.jobs),
          meta: action.jobsCategory4.meta 
        }
      }
    });  
  } else {
    return updateObject( state, {
      jobsCategory4: { s: true, payload: action.jobsCategory4 }
    });
  }
}

const fetchJobsCategory4Fail = (state, action) => {
  return updateObject( state, {
    jobsCategory4: { f: true }
  });
}

const fetchJobRequest = (state, action) => {
  return updateObject( state, {
    job: { r: true, payload: {} }
  });
}

const fetchJobSuccess = (state, action) => {
  return updateObject( state, {
    job: { s: true, payload: action.job }
  });
}

const fetchJobFail = (state, action) => {
  return updateObject( state, {
    job: { f: true }
  });
}

const postDocumentParsingRequest = (state, action) => {
  return updateObject( state, {
    documentParsing: { r: true, payload: state.documentParsing }
  });
}

const postDocumentParsingSuccess = (state, action) => {
  return updateObject( state, {
    documentParsing: { s: true, payload: action.documentParsing }
  });
}

const postDocumentParsingFail = (state, action) => {
  return updateObject( state, {
    documentParsing: { f: true }
  });
}

const postDocumentRevealingRequest = (state, action) => {
  return updateObject( state, {
    documentRevealing: { r: true, payload: state.documentRevealing }
  });
}

const postDocumentRevealingSuccess = (state, action) => {
  return updateObject( state, {
    documentRevealing: { s: true, payload: action.documentRevealing }
  });
}

const postDocumentRevealingFail = (state, action) => {
  return updateObject( state, {
    documentRevealing: { f: true }
  });
}

const jobsReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.FETCH_JOBS_REQUEST: return fetchJobsRequest( state, action );
    case actionTypes.FETCH_JOBS_SUCCESS: return fetchJobsSuccess( state, action );
    case actionTypes.FETCH_JOBS_FAIL: return fetchJobsFail( state, action );
    case actionTypes.FETCH_SIMILAR_JOBS_REQUEST: return fetchSimilarJobsRequest( state, action );
    case actionTypes.FETCH_SIMILAR_JOBS_SUCCESS: return fetchSimilarJobsSuccess( state, action );
    case actionTypes.FETCH_SIMILAR_JOBS_FAIL: return fetchSimilarJobsFail( state, action );
    case actionTypes.FETCH_RECOMMANDED_JOBS_REQUEST: return fetchRecommandedJobsRequest( state, action );
    case actionTypes.FETCH_RECOMMANDED_JOBS_SUCCESS: return fetchRecommandedJobsSuccess( state, action );
    case actionTypes.FETCH_RECOMMANDED_JOBS_FAIL: return fetchRecommandedJobsFail( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY_REQUEST: return fetchJobsCategoryRequest( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY_SUCCESS: return fetchJobsCategorySuccess( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY_FAIL: return fetchJobsCategoryFail( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY1_REQUEST: return fetchJobsCategory1Request( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY1_SUCCESS: return fetchJobsCategory1Success( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY1_FAIL: return fetchJobsCategory1Fail( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY2_REQUEST: return fetchJobsCategory2Request( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY2_SUCCESS: return fetchJobsCategory2Success( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY2_FAIL: return fetchJobsCategory2Fail( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY3_REQUEST: return fetchJobsCategory3Request( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY3_SUCCESS: return fetchJobsCategory3Success( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY3_FAIL: return fetchJobsCategory3Fail( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY4_REQUEST: return fetchJobsCategory4Request( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY4_SUCCESS: return fetchJobsCategory4Success( state, action );
    case actionTypes.FETCH_JOBS_CATEGORY4_FAIL: return fetchJobsCategory4Fail( state, action );
    case actionTypes.FETCH_JOB_REQUEST: return fetchJobRequest( state, action );
    case actionTypes.FETCH_JOB_SUCCESS: return fetchJobSuccess( state, action );
    case actionTypes.POST_DOCUMENT_PARSING_REQUEST: return postDocumentParsingRequest( state, action );
    case actionTypes.POST_DOCUMENT_PARSING_SUCCESS: return postDocumentParsingSuccess( state, action );
    case actionTypes.POST_DOCUMENT_PARSING_FAIL: return postDocumentParsingFail( state, action );
    case actionTypes.POST_DOCUMENT_REVEALING_REQUEST: return postDocumentRevealingRequest( state, action );
    case actionTypes.POST_DOCUMENT_REVEALING_SUCCESS: return postDocumentRevealingSuccess( state, action );
    case actionTypes.POST_DOCUMENT_REVEALING_FAIL: return postDocumentRevealingFail( state, action );
    case actionTypes.FETCH_JOB_FAIL: return fetchJobFail( state, action );
    case actionTypes.UPDATE_BOARD_FILTERS: return updateBoardFilters( state, action );
    case actionTypes.UPDATE_CURRENT_TAB: return updateCurrentTab( state, action );
    case actionTypes.INIT_JOBS_CATEGORIES_STATE: return initJobsCategoriesState(state, action);
    default: return state;
  }
};

export default jobsReducer;