export const FETCH_JOBS_REQUEST = 'FETCH_JOBS_REQUEST';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_FAIL = 'FETCH_JOBS_FAIL';

export const FETCH_SIMILAR_JOBS_REQUEST = 'FETCH_SIMILAR_JOBS_REQUEST';
export const FETCH_SIMILAR_JOBS_SUCCESS = 'FETCH_SIMILAR_JOBS_SUCCESS';
export const FETCH_SIMILAR_JOBS_FAIL = 'FETCH_SIMILAR_JOBS_FAIL';

export const FETCH_RECOMMANDED_JOBS_REQUEST = 'FETCH_RECOMMANDED_JOBS_REQUEST';
export const FETCH_RECOMMANDED_JOBS_SUCCESS = 'FETCH_RECOMMANDED_JOBS_SUCCESS';
export const FETCH_RECOMMANDED_JOBS_FAIL = 'FETCH_RECOMMANDED_JOBS_FAIL';

export const FETCH_JOBS_CATEGORY_REQUEST = 'FETCH_JOBS_CATEGORY_REQUEST';
export const FETCH_JOBS_CATEGORY_SUCCESS = 'FETCH_JOBS_CATEGORY_SUCCESS';
export const FETCH_JOBS_CATEGORY_FAIL = 'FETCH_JOBS_CATEGORY_FAIL';

export const FETCH_JOBS_CATEGORY1_REQUEST = 'FETCH_JOBS_CATEGORY1_REQUEST';
export const FETCH_JOBS_CATEGORY1_SUCCESS = 'FETCH_JOBS_CATEGORY1_SUCCESS';
export const FETCH_JOBS_CATEGORY1_FAIL = 'FETCH_JOBS_CATEGORY1_FAIL';

export const FETCH_JOBS_CATEGORY2_REQUEST = 'FETCH_JOBS_CATEGORY2_REQUEST';
export const FETCH_JOBS_CATEGORY2_SUCCESS = 'FETCH_JOBS_CATEGORY2_SUCCESS';
export const FETCH_JOBS_CATEGORY2_FAIL = 'FETCH_JOBS_CATEGORY2_FAIL';

export const FETCH_JOBS_CATEGORY3_REQUEST = 'FETCH_JOBS_CATEGORY3_REQUEST';
export const FETCH_JOBS_CATEGORY3_SUCCESS = 'FETCH_JOBS_CATEGORY3_SUCCESS';
export const FETCH_JOBS_CATEGORY3_FAIL = 'FETCH_JOBS_CATEGORY3_FAIL';

export const FETCH_JOBS_CATEGORY4_REQUEST = 'FETCH_JOBS_CATEGORY4_REQUEST';
export const FETCH_JOBS_CATEGORY4_SUCCESS = 'FETCH_JOBS_CATEGORY4_SUCCESS';
export const FETCH_JOBS_CATEGORY4_FAIL = 'FETCH_JOBS_CATEGORY4_FAIL';

export const FETCH_JOB_REQUEST = 'FETCH_JOB_REQUEST';
export const FETCH_JOB_SUCCESS = 'FETCH_JOB_SUCCESS';
export const FETCH_JOB_FAIL = 'FETCH_JOB_FAIL';

export const POST_DOCUMENT_PARSING_REQUEST = 'POST_DOCUMENT_PARSING_REQUEST';
export const POST_DOCUMENT_PARSING_SUCCESS = 'POST_DOCUMENT_PARSING_SUCCESS';
export const POST_DOCUMENT_PARSING_FAIL = 'POST_DOCUMENT_PARSING_FAIL';

export const POST_DOCUMENT_REVEALING_REQUEST = 'POST_DOCUMENT_REVEALING_REQUEST';
export const POST_DOCUMENT_REVEALING_SUCCESS = 'POST_DOCUMENT_REVEALING_SUCCESS';
export const POST_DOCUMENT_REVEALING_FAIL = 'POST_DOCUMENT_REVEALING_FAIL';

export const ADD_PROFILE_REQUEST = 'ADD_PROFILE_REQUEST';
export const ADD_PROFILE_SUCCESS = 'ADD_PROFILE_SUCCESS';
export const ADD_PROFILE_FAIL = 'ADD_PROFILE_FAIL';

export const PUT_PROFILE_REQUEST = 'PUT_PROFILE_REQUEST';
export const PUT_PROFILE_SUCCESS = 'PUT_PROFILE_SUCCESS';
export const PUT_PROFILE_FAIL = 'PUT_PROFILE_FAIL';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const ADD_PROFILE_STORING_REQUEST = 'ADD_PROFILE_STORING_REQUEST';
export const ADD_PROFILE_STORING_SUCCESS = 'ADD_PROFILE_STORING_SUCCESS';
export const ADD_PROFILE_STORING_FAIL = 'ADD_PROFILE_STORING_FAIL';

export const GET_PROFILE_STORING_REQUEST = 'GET_PROFILE_STORING_REQUEST';
export const GET_PROFILE_STORING_SUCCESS = 'GET_PROFILE_STORING_SUCCESS';
export const GET_PROFILE_STORING_FAIL = 'GET_PROFILE_STORING_FAIL';

export const PUT_PROFILE_STORING_REQUEST = 'PUT_PROFILE_STORING_REQUEST';
export const PUT_PROFILE_STORING_SUCCESS = 'PUT_PROFILE_STORING_SUCCESS';
export const PUT_PROFILE_STORING_FAIL = 'PUT_PROFILE_STORING_FAIL';

export const ADD_PROFILE_STORING_NOCV_REQUEST = 'ADD_PROFILE_STORING_NOCV_REQUEST';
export const ADD_PROFILE_STORING_NOCV_SUCCESS = 'ADD_PROFILE_STORING_NOCV_SUCCESS';
export const ADD_PROFILE_STORING_NOCV_FAIL = 'ADD_PROFILE_STORING_NOCV_FAIL';


export const POST_TAGGING_REQUEST = "POST_TAGGING_REQUEST";
export const POST_TAGGING_SUCCESS = "POST_TAGGING_SUCCESS";
export const POST_TAGGING_FAIL = "POST_TAGGING_FAIL";

export const GET_ASKING_REQUEST = "GET_ASKING_REQUEST";
export const GET_ASKING_SUCCESS = "GET_ASKING_SUCCESS";
export const GET_ASKING_FAIL = "GET_ASKING_FAIL";


export const  UPDATE_BOARD_FILTERS = 'UPDATE_BOARD_FILTERS';

export const UPDATE_CURRENT_TAB = 'UPDATE_CURRENT_TAB';

export const INIT_JOBS_CATEGORIES_STATE = 'INIT_JOBS_CATEGORIES_STATE';

export const REMOVE_PROFILE = 'REMOVE_PROFILE';

export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL';

export const SHOW_WELCOME_MODAL = 'SHOW_WELCOME_MODAL';